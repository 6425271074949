
import AssignServicesToCompanies from "@/components/AssignServicesToCompanies.vue";
import { Component, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {
    AssignServicesToCompanies,
  },
})
export default class AssignServicesToCompaniesView extends Vue {
  @Mutation resetSelectedCompanies;
  @Mutation resetSelectedServices;

  mounted(){
    this.resetSelectedCompanies();
    this.resetSelectedServices();
  }
}
