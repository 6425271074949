
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import CompaniesSelector from "@/components/CompaniesSelector.vue";
import SelectedCompanies from "@/components/SelectedCompanies.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import ServicesSelector from "@/components/ServicesSelector.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";
import { ServiceStatus } from "@/utils/status";

@Component({
  components: {
    Btn,
    Card,
    ServicesSelector,
    SelectedServices,
    CompaniesSelector,
    SelectedCompanies,
    Modal,
  },
  computed: {},
})
export default class AssignServicesToCompanies extends Vue {
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @State((state: StateStore) => state.data.selectedCompanies)
  selectedCompanies: Api.Service[];
  @Action updateCompanyServices;
  @Action sendEmailToCompanies;
  @Mutation resetSelectedServices;
  @Mutation resetSelectedCompanies;
  @Mutation showError;
  @Mutation showSuccess;

  showConfirmationModal = null;

  showConfModal() {
    if (_.isEmpty(this.selectedServices) || _.isEmpty(this.selectedCompanies)) {
      this.showError("Selezionare almeno un servizio e un azienda");
    } else {
      this.showConfirmationModal = true;
    }
  }

  async save() {
    const updated = await this.updateCompanyServices({
      companies: _.map(this.selectedCompanies, "guid"),
      services: _.map(this.selectedServices, "guid"),
      status: ServiceStatus.ASSIGNED,
    });
    this.showConfirmationModal = null;
    if (updated) {
      await this.sendEmailToCompanies({
        companies: _.map(this.selectedCompanies, "guid"),
        subject: "Nuovi servizi associati",
        text: "Nuovi servizi sono stati associati alla tua azienda",
      });
      this.resetSelectedServices();
      this.resetSelectedCompanies();
      this.showSuccess("Servizi correttamente associati");
    } else {
      this.showError("Errore durante l'associazione dei servizi");
    }
  }
}
